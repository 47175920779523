// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: false,
  authApi: 'ozd',
  scope: 'admin',
  platform: 'web',

  appVersion: '1.1.8-0',

  apiBaseUrl: 'https://api.test.ee.acal.ma/ee/api',
  imageBaseUrl: 'https://api.test.ee.acal.ma/ee/api/v2/public/file',
  socketIoUrl: 'https://api.test.ee.acal.ma',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'TzNYYsG1eo' },

  publicUrl: 'expertease_backoffice_dev:4211',

  /** Used for Audo Playback as well */
  voiceRecording: {
    maxDurationInMs: 20000, // Unused in Web mode
    tickIntervalInMs: 200,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
